/* global.css */
::-webkit-scrollbar {
    width: 8px;
    height: 8px; /* For horizontal scrollbar */
}

::-webkit-scrollbar-track {
    background: transparent; /* No background */
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* For Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #888 transparent; /* No background */
}
